



























































































import Vue from 'vue';
const Ripple = require('vue-ripple-directive');
import axios from 'axios';
import { isNumber } from 'highcharts';

interface EmailTarget {
  _id: string;
  topic: Array<string>;
}

const emailRegx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default Vue.extend({
  directives: {
    Ripple,
  },
  data() {
    let emails = Array<EmailTarget>();
    const fields = [
      {
        key: '_id',
        label: 'email',
      },
      {
        key: 'operation',
        label: '操作',
      },
    ];

    return {
      form: {
        effectiveRatio: 0.75,
      },
      selected: [],
      emails,
      fields,
      disconnectCheckTime: '',
    };
  },
  computed: {
    canSaveEffectiveRatio(): boolean {
      if (!this.form.effectiveRatio) return false;

      if (!isNumber(this.form.effectiveRatio)) return false;

      if (this.form.effectiveRatio > 1 || this.form.effectiveRatio < 0)
        return false;

      return true;
    },
  },
  mounted() {
    this.getEffectiveRatio();
    this.getAlertEmailTarget();
  },
  methods: {
    async getEffectiveRatio() {
      const res = await axios.get('/SystemConfig/EffectiveRatio');
      this.form.effectiveRatio = res.data;
    },
    async setEffectiveRatio() {
      const res = await axios.post('/SystemConfig/EffectiveRatio', {
        id: '',
        value: this.form.effectiveRatio.toString(),
      });
      if (res.status === 200) {
        this.$bvModal.msgBoxOk('成功', { headerBgVariant: 'info' });
      } else {
        this.$bvModal.msgBoxOk(`失敗 ${res.status} - ${res.statusText}`, {
          headerBgVariant: 'danger',
        });
      }
    },
    onEmailSelected(items: never[]) {
      this.selected = items;
    },
    async getAlertEmailTarget() {
      try {
        const res = await axios.get('/AlertEmailTargets');
        this.emails = res.data;
      } catch (err) {
        throw new Error('failed to get Alert email!');
      }
    },
    newEmail() {
      this.emails.push({
        _id: '',
        topic: [],
      });
    },
    deleteEmail(index: number) {
      this.emails.splice(index, 1);
    },
    validateEmail(index: number) {
      return emailRegx.test(this.emails[index]._id);
    },
    async saveEmail() {
      let filteredEmails = this.emails.filter(v => {
        if (!Boolean(v._id)) return false;
        return emailRegx.test(v._id.toLowerCase());
      });

      const res = await axios.post('/AlertEmailTargets', filteredEmails);
      if (res.status === 200) this.$bvModal.msgBoxOk('成功');
    },
    async testEmail(index: number) {
      const params = {
        email: this.emails[index]._id,
      };

      const res = await axios.get('/TestAlertEmail', {
        params,
      });
      if (res.status === 200) this.$bvModal.msgBoxOk('成功');
    },
    async testAllEmail() {
      try {
        const res = await axios.get('/TestAllAlertEmail');
        if (res.status === 200) this.$bvModal.msgBoxOk('成功');
      } catch (err) {
        throw new Error('failed to test email!');
      }
    },
  },
});
